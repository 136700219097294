<template>
  <div class="pa-5">
    <v-overlay class="loading-center" :value="saving">
      <lottie :options="defaultOptions" :height="300" :width="400" />
    </v-overlay>

    <v-snackbar :color="snackbarColor" top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <h1>
      Cuentas Bancarias de
      <span class="font-weight-light">
        {{ selectedBusiness.shortName }}
      </span>
    </h1>

    <v-container v-if="!loading" fluid class="screen-width fill-height">
      <v-form ref="form">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="6" md="6">
            <h2>RTN</h2>
            <v-text-field
              rounded
              outlined
              v-mask="`##############`"
              :rules="[rules.required, rules.numbers]"
              placeholder="Ingrese el RTN"
              v-model="business.tin"
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <h2>Razón social</h2>
            <v-text-field
              rounded
              outlined
              type="text"
              :rules="[rules.required, rules.letters]"
              placeholder="Ingrese la razón social"
              v-model="business.tinName"
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <h2>Nombre del banco</h2>
            <v-autocomplete
              v-model="business.bankAccounts[0].bank"
              :items="banks"
              :rules="[rules.required]"
              placeholder="Seleccione el banco"
              outlined
              item-text="name"
              item-value="value"
              rounded
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <h2>Tipo de cuenta</h2>
            <v-select
              v-model="business.bankAccounts[0].type"
              :items="accountsType"
              placeholder="Seleccione tipo de cuenta"
              outlined
              :rules="[rules.required]"
              item-text="name"
              item-value="value"
              rounded
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <h2>Nombre de la Cuenta</h2>
            <v-text-field
              type="text"
              rounded
              :rules="[rules.required]"
              outlined
              placeholder="Ingrese el nombre de la cuenta"
              v-model="business.bankAccounts[0].name"
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <h2>
              Número de la Cuenta
              <small>(Sin espacios o guiones)</small>
            </h2>
            <v-text-field
              type="number"
              rounded
              outlined
              :rules="[rules.required, rules.numbers]"
              placeholder="Ingrese el número de la cuenta"
              v-model.number="business.bankAccounts[0].number"
            />
          </v-col>

          <v-col cols="12" md="6">
            <h2>Moneda</h2>
            <v-select
              v-model="business.bankAccounts[0].currency"
              :items="currency"
              placeholder="Seleccione la moneda"
              outlined
              :rules="[rules.required]"
              item-text="name"
              item-value="value"
              rounded
            />
          </v-col>

          <v-col style="display: flex; justify-content: flex-end" cols="12">
            <v-btn
              :loading="loading"
              height="50"
              width="200"
              @click="updateAccounts"
              color="primary"
              dark
              >Guardar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "users-sudo",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings, ledgerMixin],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "red",
      rules: {
        letters: (v) =>
          /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/.test(v) ||
          "Solo es permitido el ingreso de letras",
        required: (value) => !!value || "Obligatorio",
        array: (value) => (value && value.length) || "Obligatorio",

        numbers: (v) =>
          /^[0-9]+$/.test(v) || "Solo es permitido el ingreso de números",

        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      business: {},
      accountsType: [
        { name: "Ahorro", value: "savings" },
        { name: "Cheques", value: "checkings" },
      ],
      currency: [
        { name: "Lempiras (L)", value: "lps" },
        { name: "Dólares ($)", value: "usd" },
      ],
      banks: [
        { value: 1, name: "Banco Central de Honduras" },
        { value: 2, name: "Banco Atlántida" },
        { value: 3, name: "Banco Ficohsa CB" },
        { value: 4, name: "Banco de Trabajadores" },
        { value: 5, name: "Banco de Occidente" },
        { value: 6, name: "Banco de Honduras" },
        { value: 7, name: "Banco Hondureño del Cafe (Banhcafe)" },
        { value: 8, name: "Banco del País (Banpais)" },
        { value: 9, name: "Banco Lafise" },
        { value: 10, name: "Banco Ficensa" },
        { value: 11, name: "BAC Honduras" },
        { value: 12, name: "Banco Promerica" },
        { value: 13, name: "Banco Ficohsa" },
        { value: 14, name: "Banco Davivienda" },
        { value: 15, name: "BanRural" },
        { value: 16, name: "Banco Azteca" },
        { value: 17, name: "Banco Nacional de Desarrollo Agricola( Banadesa)" },
        { value: 18, name: "Financiera CrediQ" },
        { value: 19, name: "Banco Popular" },
      ],
    };
  },
  watch: {
    selectedBusiness() {
      // This if statement is never called JoseV.
      if (this.suscriptionBusiness) {
        this.suscriptionBusiness();
      }

      this.business = null;
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },
  methods: {
    updateAccounts() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        let prevValue = this.selectedBusiness;
        db.collection("businesses")
          .doc(this.selectedBusiness[".key"])
          .update({
            bankAccounts: this.business.bankAccounts,
            modifiedAt: new Date(),
            modifiedBy: this.$store.state.user[".key"],
            tin: this.business.tin ? this.business.tin : "",
            tinName: this.business.tinName ? this.business.tinName : "",
          })
          .then(() => {
            this.snackbarText = `Cuenta bancaria actulizada exitosamente`;
            this.snackbar = true;
            this.saving = false;
            this.snackbarColor = "green";

            this.createLedger({
              source_id: this.selectedBusiness[".key"],
              source_type: "businesses",
              currentValue: this.selectedBusiness,
              prevValue: prevValue,
            });
          })
          .catch((err) => {
            this.snackbarText = `Ocurio un error: ${err}`;
            this.snackbar = true;
            this.saving = false;
            this.snackbarColor = "red";
          });
      } else {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        this.snackbarColor = "red";
      }
    },
    async getBusinessData() {
      this.loading = true;
      let business = this.$binding(
        "business",
        db.collection("businesses").doc(this.selectedBusiness[".key"])
      );

      await Promise.all([business]);

      if (!this.business.bankAccounts) {
        this.business.bankAccounts = [
          {
            name: "",
            bank: "",
            currency: "",
            number: "",
            type: "",
          },
        ];
      }
      this.loading = false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.$store.commit("setSearchTerm", "Cuenta");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.getBusinessData();
  },
};
</script>

<style>
.select-city {
  border-radius: 50px;
  padding: 0;
}
</style>
